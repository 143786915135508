// Copyright (C) 2021 TANNER AG

import { useState } from "react";
import { GUID, JobCreationParams, ServiceError } from "../../types";
import useFetcher from "../usefetcher";
import useJobGuid from "./usejobguid";
import useFileUpload from "./usefileupload";
import { apiRoutes } from "../../utils";
import useJobs from "./usejobs";
import useAlert from "../usealert";

const useJobCreation = (closeDialog: () => void) => {
    const fetcher = useFetcher();
    const { fetchGuid } = useJobGuid();
    const { fetchFileUpload } = useFileUpload();
    const { refetch } = useJobs();
    const { showMessage } = useAlert();
    const [loading, setLoading] = useState(false);
    const [loadingLabel, setLoadingLabel] = useState("...");
    const [error, setError] = useState("");

    const fetchCreation = async (params: JobCreationParams): Promise<ServiceError | undefined> => {
        try {
            const response = await fetcher(apiRoutes.jobCreation, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(params)
            });

            if (!response.ok) {
                return { id: "", message: await response.text() };
            }

            return;
        } catch (err) {
            return { id: "", message: err.message, cause: err.stack };
        }
    };

    const fetchUploads = async (guid: GUID, files: File[]) => {
        const uploadResult = await Promise.all(files.map((file) => fetchFileUpload(guid, file)));
        return uploadResult?.find((result) => result.error)?.error;
    };

    const validate = (params: Pick<JobCreationParams, "name" | "options" | "jobType">, files: File[]): boolean => {
        if (!params?.name?.trim()) {
            setError("Name is required.");
            return false;
        }

        if (!params.options?.length) {
            setError("Select at least one option.");
            return false;
        }

        if (!files?.length) {
            setError("Select at least one file.");
            return false;
        }

        setError("");
        return true;
    };

    const revalidate = (params: Pick<JobCreationParams, "name" | "options" | "jobType">, files: File[]) => {
        // Revalidate only if error has already thrown
        if (!error) {
            return true;
        }

        return validate(params, files);
    };

    const createJob = async (params: Pick<JobCreationParams, "name" | "options" | "jobType">, files: File[]) => {
        if (!validate(params, files)) {
            return;
        }

        try {
            setLoading(true);

            setLoadingLabel("Retrieve job GUID...");
            const guid = await fetchGuid();

            if (!guid) {
                return;
            }

            setLoadingLabel("Upload files...");
            const uploadError = await fetchUploads(guid, files);
            if (uploadError) {
                setError(uploadError?.message || "Undefined error");
                setLoading(false);
                return;
            }

            setLoadingLabel("Create job...");
            const creationError = await fetchCreation({ ...params, id: guid });
            if (creationError) {
                setError(creationError?.message || "Undefined error");
                setLoading(false);
                return;
            }

            // Close creation dialog only if creation was successful
            closeDialog();

            // Refresh jobs to add show the created job
            refetch();

            // Show user message if job creation was successful
            showMessage("Job was created successfully");
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { loading, error, createJob, loadingLabel, revalidate };
};

export default useJobCreation;
