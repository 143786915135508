// Copyright (C) 2021 TANNER AG

export enum JobStatus {
    IN_QUEUE = "IN_QUEUE",
    IN_PROGRESS = "IN_PROGRESS",
    FINISHED_SUCCESS = "FINISHED_SUCCESS",
    FINISHED_WARNING = "FINISHED_WARNING",
    FINISHED_ERROR = "FINISHED_ERROR",
    SYSTEM_ERROR = "SYSTEM_ERROR",
    NONE = "none",
    NA = "NA"
}

export type StatusType = "job" | "file" | "result";

export const jobtypeColors: { [key: string]: string } = {
    default: "#f5f5f5",
    vector: "#ec6602",
    pdf: "#ff9000",
    pixel: "#00557c",
    dxf: "#33adad",
    "3d": "#33adad",
};

export const jobStatusColors = {
    [JobStatus.IN_QUEUE]: "#d9d9d6",
    [JobStatus.IN_PROGRESS]: "#ffd732",
    [JobStatus.FINISHED_SUCCESS]: "#00af8e",
    [JobStatus.FINISHED_WARNING]: "#ff9000",
    [JobStatus.FINISHED_ERROR]: "#ef0137",
    [JobStatus.SYSTEM_ERROR]: "#500078",
    [JobStatus.NONE]: "#000",
    [JobStatus.NA]: "#000"
};

export const jobStatusContrastColors = {
    [JobStatus.IN_QUEUE]: "#000",
    [JobStatus.IN_PROGRESS]: "#000",
    [JobStatus.FINISHED_SUCCESS]: "#fff",
    [JobStatus.FINISHED_WARNING]: "#fff",
    [JobStatus.FINISHED_ERROR]: "#fff",
    [JobStatus.SYSTEM_ERROR]: "#fff",
    [JobStatus.NONE]: "#fff",
    [JobStatus.NA]: "#fff"
};

export const jobStatusLabels = {
    [JobStatus.IN_QUEUE]: "In queue",
    [JobStatus.IN_PROGRESS]: "In progress",
    [JobStatus.FINISHED_SUCCESS]: "Finished",
    [JobStatus.FINISHED_WARNING]: "Finished with warnings",
    [JobStatus.FINISHED_ERROR]: "Finished with errors",
    [JobStatus.SYSTEM_ERROR]: "System error",
    [JobStatus.NONE]: "None",
    [JobStatus.NA]: "n.a."
};

export const fileStatusLabels = {
    [JobStatus.IN_QUEUE]: "In queue",
    [JobStatus.IN_PROGRESS]: "In progress",
    [JobStatus.FINISHED_SUCCESS]: "Success",
    [JobStatus.FINISHED_WARNING]: "Warnings",
    [JobStatus.FINISHED_ERROR]: "Errors",
    [JobStatus.SYSTEM_ERROR]: "System error",
    [JobStatus.NONE]: "None",
    [JobStatus.NA]: "n.a."
};

export const getStatusColor = (status: JobStatus) => jobStatusColors[status];
export const getStatusContrastColor = (status: JobStatus) => jobStatusContrastColors[status];

export const getStatusLabel = (status: JobStatus, type?: StatusType) => {
    if (type === "result" || type === "file") {
        return fileStatusLabels[status] || status;
    }

    return jobStatusLabels[status] || status;
};

export const isDeletable = (status?: JobStatus) => status !== JobStatus.IN_PROGRESS;
