// Copyright (C) 2022 TANNER AG

import React from "react";
import PageActions from "../PageActions";
import Button from "@material-ui/core/Button";
import { DownloadIcon, ImageIcon, TrashIcon } from "../Icons";
import { Job } from "../../types";
import { apiBaseUrl, apiRoutes } from "../../utils";
import useJobDelete from "../../hooks/fetch/usejobdelete";
import { isDeletable } from "../../status";
import useJobDbImport from "../../hooks/fetch/usejobdbimport";

type Props = {
    job?: Job;
};

const JobDetailsActions: React.FC<Props> = ({ job }) => {
    const { dbImport, loading: dbImportLoading } = useJobDbImport(job);
    const { deleteJob, loading: deleteJobLoading } = useJobDelete(job);

    return (
        <PageActions>
            {isDeletable(job?.status) && (
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<TrashIcon />}
                    onClick={() => deleteJob(true)}
                    disabled={deleteJobLoading}
                >
                    Delete Job
                </Button>
            )}
            {job?.damimportUrl && (
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<ImageIcon />}
                    onClick={dbImport}
                    disabled={Boolean(dbImportLoading || job?.damimportStatus)}
                >
                    Import to BildDB
                </Button>
            )}
            {job?.packageUrl && (
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    component="a"
                    href={apiBaseUrl + job?.packageUrl}
                    target="_blank"
                >
                    Download Package
                </Button>
            )}
            <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={<DownloadIcon />}
                component="a"
                href={`${apiBaseUrl}${apiRoutes.jobDetails}${job?.id}?format=csv`}
                target="_blank"
            >
                Download CSV Result
            </Button>
        </PageActions>
    );
};

export default JobDetailsActions;
