// Copyright (C) 2021 TANNER AG

import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { GUID, JobDetails as JobDetailsType } from "../../types";
import JobDetailsActions from "./JobDetailsActions";
import JobDetailsFiles from "./JobDetailsFiles";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SIHighlight from "../SIHighlight";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import StatusLabel from "../status/StatusLabel";
import { formatDateStr } from "../../utils";

type Props = {
    id?: GUID;
    job?: JobDetailsType;
};

const JobDetails: React.FC<Props> = ({ id, job }) => {
    const classes = useStyles();
    return (
        <>
            <Box display="flex" mt={3} mb={3}>
                <Typography variant="h2" gutterBottom className={classes.title}>
                    <SIHighlight fullHeight />
                    <div className={classes.titleInner}>
                        <Breadcrumbs
                            separator={<span>⦁</span>}
                            classes={{ root: classes.breadcrumbs, separator: classes.separator }}
                        >
                            {[
                                job?.jobType?.groupName,
                                formatDateStr(job?.createdAt || ""),
                                job?.jobType?.department
                            ].map((item, index) => (
                                <Typography key={index} className={classes.breadcrumb} variant="h4">
                                    {item}
                                </Typography>
                            ))}
                            {job?.status && (
                                <StatusLabel status={job?.status} classes={{ label: classes.statusLabel }} />
                            )}
                        </Breadcrumbs>
                        <span>{job?.name || id}</span>
                    </div>
                </Typography>
                <JobDetailsActions job={job} />
            </Box>
            <JobDetailsFiles job={job} />
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(2)
        },
        titleInner: {
            display: "flex",
            flexDirection: "column"
        },
        breadcrumbs: {
            marginBottom: theme.spacing(0.75)
        },
        breadcrumb: {
            color: theme.palette.text.primary,
            fontSize: "1rem",
            textTransform: "uppercase"
        },
        separator: {
            color: theme.palette.text.primary
        },
        statusLabel: {
            textTransform: "uppercase",
            fontWeight: 700
        }
    })
);

export default JobDetails;
