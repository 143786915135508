// Copyright (C) 2021 TANNER AG

import React from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import { routes } from "../utils";
import useJob from "../hooks/fetch/usejob";
import JobDetails from "../components/jobdetails/JobDetails";
import ErrorAlert from "../components/ErrorAlert";
import Page from "../components/Page";
import Spinner from "../components/Spinner";

const JobDetailsPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { job, error, loading } = useJob(id);

    return (
        <Page>
            <Breadcrumbs data={[{ label: "Job Overview", href: routes.jobOverview }, { label: job?.name || id }]} />
            {error && <ErrorAlert title={error.message} cause={error.cause} />}
            <Spinner show={loading} />
            {job && <JobDetails id={id} job={job} />}
        </Page>
    );
};

export default JobDetailsPage;
