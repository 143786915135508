// Copyright (C) 2021 TANNER AG

import { Job } from "../../types";
import useFetcher from "../usefetcher";
import { useState } from "react";
import useAlert from "../usealert";

const useJobDbImport = (job?: Job) => {
    const fetcher = useFetcher();
    const { showMessage } = useAlert();
    const [loading, setLoading] = useState(false);

    const dbImport = async () => {
        if (!job?.damimportUrl) {
            return;
        }

        try {
            setLoading(true);

            const response = await fetcher(job.damimportUrl, { method: "POST" });
            if (!response.ok) {
                showMessage(`Error occurred while requesting BildDB import. ${await response.text()}`);
                return;
            }

            showMessage("BildDB import was requested successfully.");
            return;
        } catch (err) {
            showMessage(`Error occurred while requesting BildDB import. ${err.message}`);
            return;
        } finally {
            setLoading(false);
        }
    };

    return { dbImport, loading };
};

export default useJobDbImport;
